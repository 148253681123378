
<template>
  <div>
    <Background :background="'left'" :darken-top="false" />
    <!--    <div class="global__illustration-overlay&#45;&#45;centered" style="background-image: url('../assets/demo_illustration.png');"></div>-->
    <OverlayLogo />
    <slot />
  </div>
</template>

<style scoped>
  .pos-abs {
    position: absolute;
  }
</style>